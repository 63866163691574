.main-rapport {
  // background-image: url('../../../images/StrategicGame/group_5170.png');
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  // background-color: #ffffff;
  flex-direction: column;

  
}

.rapport_day_7_msg{
  position: absolute;
  bottom:0px;
  z-index: 222;

  .rapport_day_7_text{
     position:absolute;
     background: #FFFFFF 0% 0% no-repeat padding-box;
     border-radius: 4px;
     bottom:10px;
     left:200px;
     width:  350px;
     padding: 10px;
    //  border:1px solid #000;
     &::after {
       z-index: -1;
      content: '';
      background-color: rgb(255, 253, 253);
      width: 60px;
      height: 48px;
      -webkit-clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      clip-path: polygon(50% 10%, 50% 90%, 0% 50%);
      position: absolute;
      left: -8%;
      top: 25%;
      border: 1px solid #f3f3f3;
    }
     span{
      text-align: left;
      font: normal normal bold 16px/17px Karla;
      letter-spacing: 0.32px;
      color: #71B6F9;
      text-transform: uppercase;
      opacity: 1;
      margin-bottom: 10px;
     }
     p{
       margin-top: 10px;
      text-align: left;
      font: normal normal normal 14px/17px Karla;
      letter-spacing: 0.28px;
      color: #343A40;
      opacity: 1;
     }
  }

  img{
    height: 230px;
    width: 200px;
  }
}
.rap-img {
  position: relative;
  flex: 5;
  width: 80%;
  // height: 64%;
  margin: 0px auto;
  // background-image: url(/static/media/group_5170.b615878e.jpg);
  // background-image: url('../../../images/StrategicGame/group_5172.png');

  background-size: contain;
  background-repeat: no-repeat;
  // border: 1px solid red;

  img{
    width: 100%;
    height: 100%;
  }
}

.footer-rapport {
  flex: 1;
  display: flex;
  height: 40px;
  justify-content: space-between;
  flex-direction: row;
  margin: 0px 10%;
  div {
    // display: flex;
  }
}

%rap-msag-label {
  z-index: 200;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font: normal normal bold 12px/14px Karla;
  letter-spacing: 0.24px;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
}

.rap-msag-label-1 {
  @extend %rap-msag-label;
  top: 16%;
  left: 35%;



}
.rap-msag-label-2 {
  @extend %rap-msag-label;
  top: 49%;
  left: 52%;

  
}
.rap-msag-label-3 {
  @extend %rap-msag-label;
  top: 24%;
  left: 66%;
}
