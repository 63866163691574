/*
Template Name: Adminto - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

//Fonts
@import 'custom/fonts/fonts';

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/mixins.scss';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/left-menu';
@import 'custom/structure/topbar';
@import 'custom/structure/footer';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/layouts';

//Components
@import 'custom/components/helper';
@import 'custom/components/social';
@import 'custom/components/widgets';
@import 'custom/components/custom-checkbox';
@import 'custom/components/custom-radio';
@import 'custom/components/ribbons';
@import 'custom/components/print';
@import 'custom/components/preloader';
@import 'custom/components/accordions';
@import 'custom/components/submenu';
@import 'custom/components/wizard';
@import 'custom/components/timeline';

// pages
@import 'custom/pages/components-demo';
@import 'custom/pages/email';
@import 'custom/pages/authentication';
@import 'custom/pages/error';
@import 'custom/pages/taskboard';
@import 'custom/pages/taskdetails';
@import 'custom/pages/profile';
@import 'custom/pages/pricing';
@import 'custom/pages/timeline';
@import 'custom/pages/gallery';
@import 'custom/pages/coming-soon';
@import 'custom/pages/quiz';
@import 'custom/pages/module';
@import 'custom/pages/listQuiz';
@import 'custom/pages/dashboard';
@import 'custom/pages/configuration-module';
@import 'custom/pages/notions-module';
@import 'custom/pages/sessions-module';
@import 'custom/pages/atterissage';
@import 'custom/pages/stratEdgeResults';
@import 'custom/pages/simulations';

@import 'custom/pages/strategic-game';
@import 'custom/pages/classement';
@import 'custom/pages/rapport';
@import 'custom/pages/dayone';

// Vendors
@import 'vendor/metisMenu';

// Plugins
@import 'custom/plugins/waves';
@import 'custom/plugins/slimscroll';
@import 'custom/plugins/sweetalert';
@import 'custom/plugins/toastr';
@import 'custom/plugins/ion-rangeslider';
@import 'custom/plugins/bootstrap-tagsinput';
@import 'custom/plugins/multiple-select';
@import 'custom/plugins/select2';
@import 'custom/plugins/timepicker';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/colorpicker';
@import 'custom/plugins/daterange';
@import 'custom/plugins/parsley';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/dropify';
@import 'custom/plugins/form-editor';
@import 'custom/plugins/x-editable';
@import 'custom/plugins/datatable';
@import 'custom/plugins/responsive-table';
@import 'custom/plugins/tablesaw';
@import 'custom/plugins/flot';
@import 'custom/plugins/morris';
@import 'custom/plugins/chartist';
@import 'custom/plugins/chartjs';
@import 'custom/plugins/sparkline-chart';
@import 'custom/plugins/calendar';
@import 'custom/plugins/summernote';
@import 'custom/plugins/hopscotch';
@import 'custom/plugins/jstree';
@import 'custom/plugins/google-maps';
@import 'custom/plugins/vector-maps';


.highcharts-exporting-group {
    display: none;
}

.highcharts-credits {
    display: none;
}