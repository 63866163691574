.sub_link {
  text-align: left;
  font: 14px/19px Roboto;
  letter-spacing: 0px;
  color: #435966;
  opacity: 1;
}

.sub_link_parent {
  display: list-item !important;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 10px;
  &:hover {
    background-color: #fafafa;
  }
}
.sub_ul_link {
  position: fixed;
  background-color: #ffffff;
  padding-left: 0px !important;
  left: 67px;
  display: inline-block;
  padding: 0px;
  box-shadow: 0px 3px 6px #0000001a;
}

.submenu_li {
  &:hover {
    .submenu_a {
      width: auto;
    }

    .submenu_a_lable {
      display: inline-block;
    }
  }
}

.submenu_a {
  background-color: #ffffff;

  &:hover {
    background-color: #fafafa;
  }
}
.submenu_a_lable {
  margin-left: 18px;
  display: none;
}
